import { Add, Delete, Info as MaterialInfo } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ToastNotification from '~/components/ToastNotification'
import ViewLoading from '~/components/ViewLoading'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { getActivity } from '~/services/Activity'
import { getCompanySimple } from '~/services/CompaniesAndBranches'
import { IBaseResponse } from '~/services/RequestService/types'
import { getPark, getSiteId, postSite, putSite } from '~/services/Site'
import { deleteSiteActivity, getSiteActivity, postSiteActivity } from '~/services/SiteActivity'
import ErrorToast from '~/utils/toastErrorCatch'
import { validarNumeroRealPositivo } from '~/utils/Validator'
import IMAGE from '../../../assets/images/image.png'
import { ICompaniesSimple } from '../CompaniesAndBranches/types'
import ImageUploadModal from '../Sites/components/ImagemReferencia'
import MyModal from '../Sites/components/TableUserSiteModal'
import * as S from './styles'
import { ImageValuesProps, Registration, RegistrationError } from './types'
import { t } from 'i18next'

const SiteCreateAndUpdate = () => {
  const [registration, setRegistration] = useState<Registration>(new Registration())
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError())
  const [image, setImage] = useState<string>(IMAGE)
  const [imageSet, setImageSet] = useState<File>()
  const [loading, setLoading] = useState(false)
  const [verifyPark, setVerifyPark] = useState<boolean>()
  const [imagesData, setImagesData] = useState<ImageValuesProps>()
  const [activitiesData, setActivitiesData] = useState<any[]>([])
  const [activitiesList, setActivitiesList] = useState<any[]>([])
  const [openActivityModalOperation, setOpenActivityModalOperation] = useState(false)
  const [activityModalOperationMode, setActivityModalOperationMode] = useState<'add' | 'delete'>('add')
  const [selectedActivity, setSelectedActivity] = useState<any>()
  const [linkToDownload, setLinkToDownload] = useState({
    orthomosaic: '',
    diagram: '',
  })
  const [stringCoordinates, setStringCoordinates] = useState<string>('')

  const params = useParams()
  const userId = params
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAuth()

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0]
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage))
    }
  }
  const [companies, setCompanies] = useState<ICompaniesSimple[]>([])
  const [isRestricted, setIsRestricted] = useState(true)

  useEffect(() => {
    if (!registration.companyId && user?.accessLevel === 2) {
      setRegistration({ ...registration, companyId: user.companyId ?? '' })
    }
  }, [user, registration, setRegistration])

  useEffect(() => {
    if (user?.accessLevel === 1 || user?.accessLevel === 2) {
      setIsRestricted(false)
    } else {
      setIsRestricted(true)
    }
  }, [])

  const fetchSiteActivities = async (siteId: string) => {
    try {
      const activitiesResponse = await getSiteActivity({ siteId })
      setActivitiesData(activitiesResponse.data)
    } catch (error) {
      return <ErrorToast message={t('Não foi possível buscar as atividades.')} />
    }
  }

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesData = await getCompanySimple({
          ConsiderUserCompany: true,
          OnlyHeadOffices: false,
        })
        setCompanies(companiesData.data)
      } catch (error) {
        return <ErrorToast message={t('Não foi possível buscar as empresas.')} />
      }
    }

    const fetchActivitiesList = async () => {
      try {
        const response = await getActivity()
        if (response.success) {
          setActivitiesList(response.data)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          })
        }
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    }

    fetchCompanies()
    fetchActivitiesList()
    params.siteId && fetchSiteActivities(params.siteId)
  }, [])

  useEffect(() => {
    params.siteId && fetchSiteActivities(params.siteId)
  }, [openActivityModalOperation])

  const agroupmentCordinates = (latitude: string, longitude: string) => {
    if (latitude && longitude) {
      setStringCoordinates(`${latitude}, ${longitude}`)
    }
  }

  const verifyData = (value: any) => {
    return value === null || !value ? '' : value
  }

  const textVerifyPark = (value: boolean) => {
    if (!value) {
      return t('Não existe parque importado do ArcGis com esse nome.')
    } else {
      return t('Parque encontrado no ArcGis.')
    }
  }

  const getHelperTextColor = (isParkFound?: boolean) => {
    return isParkFound ? 'green' : 'orange'
  }

  const verifyNamePark = async () => {
    try {
      const parkData = await getPark(registration.ArcGisName)
      if (parkData.success) {
        setVerifyPark(parkData.data)
        const resultText = textVerifyPark(parkData.data)
        setRegistrationError((prevErrors) => ({
          ...prevErrors,
          ArcGisName: resultText,
        }))
      }
    } catch (error) {
      return <ErrorToast message={t('Não foi possível verificar o nome do parque.')} />
    }
  }

  const loadSite = async () => {
    setLoading(true)
    try {
      const response = await getSiteId(params.siteId)
      if (response.success) {
        const result = response.data

        setRegistration({
          id: verifyData(result.id),
          name: verifyData(result.name),
          local: verifyData(result.local),
          descricao: verifyData(result.description),
          powerOutput: verifyData(result.powerOutput),
          inverters: verifyData(result.inverters),
          mounting: verifyData(result.mounting),
          moduleType: verifyData(result.moduleType),
          moduleLayout: verifyData(result.moduleLayout),
          panelInfo: verifyData(result.panelInfo),
          latitudeSite: verifyData(result.latitude),
          longitudeSite: verifyData(result.longitude),
          coverImageUrl: verifyData(result?.coverImage?.fileUrl) ?? '',
          OrthomosaicImage: verifyData(result?.orthomosaicImage?.fileUrl) ?? '',
          electricalDiagramImage: verifyData(result?.electricalDiagramImage?.fileUrl) ?? '',
          companyId: verifyData(result.companyId),
          PanelHeight: result.panelHeight,
          CommissioningDate: result.commissioningDate,
          ArcGisName: result.arcGisName,
        })

        setImagesData({
          electricalDiagram: { ...result.electricalDiagramImage },
          orthomosaic: { ...result.orthomosaicImage },
          moduleReferenceImage: { ...result.moduleReferenceImage },
        })

        setLinkToDownload({
          diagram: result?.electricalDiagramImage?.fileUrl ?? '',
          orthomosaic: result?.orthomosaicImage?.fileUrl ?? '',
        })

        agroupmentCordinates(result.latitude, result.longitude)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          errorMessage: response.errorDetails,
          errors: response.errors,
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const handleChangePainel = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value

    inputValue = inputValue.replace(/[^\d.]/g, '')

    const decimalCount = (inputValue.match(/\./g) || []).length
    if (decimalCount > 1) {
      inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'))
    }
    setRegistration({ ...registration, PanelHeight: inputValue })
  }

  useEffect(() => {
    if (params.siteId) loadSite()
  }, [])

  const RegisterAndEditSite = async () => {
    setLoading(true)

    const errors = { ...registrationError }
    let isValid = true

    if (!registration.name.trim()) {
      errors.name = t('Nome é obrigatório.')
      isValid = false
    } else if (registration.name.trim().length < 5) {
      errors.name = t('Nome deve ter mais de 5 caracteres.')
      isValid = false
    } else if (registration.name.trim().length > 200) {
      errors.name = t('Nome deve ter menos de 200 caracteres.')
      isValid = false
    } else {
      errors.name = ''
    }

    if (!registration.local.trim()) {
      errors.local = t('Local é obrigatório.')
      isValid = false
    } else if (registration.local.trim().length < 1) {
      errors.local = t('Local deve ter mais de 1 caracteres.')
      isValid = false
    } else if (registration.local.trim().length > 200) {
      errors.local = t('Local deve ter menos de 200 caracteres.')
      isValid = false
    } else {
      errors.local = ''
    }

    if (!registration.powerOutput.trim()) {
      errors.powerOutput = t('Total MWDC é obrigatório.')
      isValid = false
    } else {
      const powerValue = parseFloat(registration.powerOutput)
      if (isNaN(powerValue) || powerValue <= 0) {
        errors.powerOutput = t('Power output deve ser maior que 0.')
        isValid = false
      } else {
        errors.powerOutput = ''
      }
    }

    if (registration.descricao && registration.descricao.trim().length > 500) {
      errors.descricao = t('Descrição deve ter menos de 500 caracteres.')
      isValid = false
    } else {
      errors.descricao = ''
    }

    if (registration.inverters && registration.inverters.trim().length > 100) {
      errors.inverters = t('Inversores deve ter menos de 100 caracteres.')
      isValid = false
    } else {
      errors.inverters = ''
    }

    if (registration.mounting && registration.mounting.trim().length > 100) {
      errors.mounting = t('Fabricante Tracker deve ter menos de 100 caracteres.')
      isValid = false
    } else {
      errors.inverters = ''
    }

    if (registration.moduleType && registration.moduleType.trim().length > 100) {
      errors.moduleType = t('Fabricante e Modelo do Módulo deve ter menos de 100 caracteres.')
      isValid = false
    } else {
      errors.moduleType = ''
    }

    if (registration.moduleLayout && registration.moduleLayout.trim().length > 100) {
      errors.moduleLayout = t('Quantidade de Módulos por String deve ter menos de 100 caracteres.')
      isValid = false
    } else {
      errors.moduleLayout = ''
    }

    if (registration.panelInfo && registration.panelInfo.trim().length > 200) {
      errors.panelInfo = t('Informações Adicionais dos Painéis deve ter menos de 200 caracteres.')
      isValid = false
    } else {
      errors.panelInfo = ''
    }

    const panelHeightNumber = Number(registration.PanelHeight)
    if (isNaN(panelHeightNumber) || panelHeightNumber <= 0 || panelHeightNumber >= 100) {
      errors.PanelHeight = t('O valor de Panel Height deve ser maior que 0 e menor que 100.')
      isValid = false
    } else {
      errors.PanelHeight = ''
    }

    setRegistrationError(errors)

    if (!isValid) {
      setLoading(false)
      return
    }

    const coordinatesArray = stringCoordinates.replace(/, /g, ';').replace(/,/g, '.').split(';')

    const latitude = coordinatesArray[0].trim()
    const longitude = coordinatesArray[1].trim()

    try {
      const panelHeightNumber = registration.PanelHeight as number

      const data = new FormData()
      if (params.siteId) data.append('SiteId', params.siteId)
      data.append('Name', registration.name)
      data.append('Local', registration.local)
      data.append('Description', registration.descricao ?? '')
      data.append('PowerOutput', registration.powerOutput ?? '')
      data.append('Inverters', registration.inverters ?? '')
      data.append('Mounting', registration.mounting ?? '')
      data.append('ModuleType', registration.moduleType ?? '')
      data.append('ModuleLayout', registration.moduleLayout ?? '')
      data.append('PanelInfo', registration.panelInfo ?? '')
      data.append('Latitude', latitude ?? '')
      data.append('Longitude', longitude ?? '')
      data.append('CoverImage', (imageSet as File) ?? '')
      data.append('ElectricalDiagramImage', (selectedDiagram as File) ?? '')
      data.append('OrthomosaicImage', (selectedOrtomosaic as File) ?? '')
      data.append('CompanyId', registration.companyId)
      data.append('PanelHeight', panelHeightNumber.toString())
      data.append('CommissioningDate', registration.CommissioningDate ?? '')
      data.append('ArcGisName', registration.ArcGisName ?? '')

      let response: IBaseResponse<boolean>
      if (params.siteId) response = await putSite(data, params.siteId)
      else response = await postSite(data)

      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: params.siteId
            ? t('Edição realizada com sucesso!')
            : t('Cadastro realizado com sucesso!'),
        })
        navigate(-1)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: params.siteId
            ? t('Não foi possível realizar a edição, tente novamente mais tarde.')
            : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: params.siteId
          ? t('Não foi possível realizar a edição, tente novamente mais tarde.')
          : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setRegistration({
      ...registration,
      [name]: value,
    })
  }

  const getImageComponent = () => {
    if (params.siteId) {
      if (registration.coverImageUrl && !imageSet) {
        return <S.Imagem src={registration.coverImageUrl ?? image} width="320px" height="320px" />
      }
    }
    return image ? <S.Imagem src={image} width="320px" height="320px" /> : null
  }

  const diagramInputRef = useRef<HTMLInputElement>(null)
  const ortomosaicInputRef = useRef<HTMLInputElement>(null)

  const [selectedOrtomosaic, setSelectedOrtomosaic] = useState<File | null>(null)
  const [selectedDiagram, setSelectedDiagram] = useState<File | null>(null)

  const handleOrtomosaicClick = () => {
    ortomosaicInputRef.current?.click()
  }

  const handleOrtomosaicChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedOrtomosaic(event.target.files[0])
    }

    handleImageChange(event)
  }

  const handleDiagramClick = () => {
    diagramInputRef.current?.click()
  }

  const handleDiagramChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedDiagram(event.target.files[0])
    }

    handleImageChange(event)
  }

  const handleImageChange = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    let file = null
    let newThumbnail: string = ''
    if (e?.target?.files) file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const img = new Image()
        img.src = reader.result as string
        img.onload = async () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          if (ctx) ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          return canvas.toDataURL('image/jpeg')
        }
        const { onload } = img

        return onload
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDownload = (imageUrl: string, fileName: string) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível realizar o download.'),
        })
      })
  }

  return (
    <BaseLayout title={params.siteId ? t('Editar Sítio') : t('Cadastrar Sítio')}>
      {loading ? (
        <ViewLoading />
      ) : (
        <S.ContainerForm as="form" onSubmit={RegisterAndEditSite}>
          <S.Container className="">
            <S.Flex className="full">
              <S.Label
                htmlFor="input-file-imagem-site"
                style={{ height: 'auto', flexDirection: 'row', cursor: 'pointer' }}
              >
                <Box sx={{ position: 'relative' }}>
                  <span style={{ display: 'flex', marginBottom: '4px', justifyContent: 'center' }}>
                    {t('Imagem Aérea do Sítio')}
                  </span>
                  {getImageComponent()}
                  <S.Input
                    type="file"
                    accept="image/*"
                    id="input-file-imagem-site"
                    onChange={handleChange}
                    style={{ display: 'none', width: '320px', height: '320px' }}
                    disabled={isRestricted}
                  />
                  <S.EditIcon>
                    <EditIcon color="inherit" />
                  </S.EditIcon>
                </Box>
              </S.Label>
              <S.Flex className="col  full">
                <Autocomplete
                  id="companies-Sitio"
                  options={companies}
                  getOptionLabel={(option) => option.corporateName + ' - ' + option.fantasyName}
                  value={
                    companies.find(
                      (company) =>
                        company.id ===
                        ((user ?? { accessLevel: null, companyId: null }).accessLevel !== 1
                          ? (user ?? { companyId: null }).companyId
                          : registration.companyId),
                    ) ?? null
                  }
                  onChange={(event, newValue) => {
                    setRegistration({ ...registration, companyId: newValue ? newValue.id : '' })
                  }}
                  size="small"
                  disabled={user?.accessLevel !== 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={t('Empresa Responsável')}
                      variant="outlined"
                      disabled={user?.accessLevel !== 1}
                    />
                  )}
                  clearOnEscape
                  sx={{ marginTop: '24px', marginBottom: '10px' }}
                />
                <S.InputWrapper>
                  <S.MaterialInput
                    size="small"
                    placeholder={t('') ?? ''}
                    id="input-name"
                    value={registration.name}
                    onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
                    required
                    disabled={isRestricted}
                    label={t('Nome')}
                    error={!!registrationError.name}
                    helperText={registrationError.name}
                    onClick={() => setRegistrationError({ ...registrationError, name: '' })}
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.MaterialInput
                    size="small"
                    id="input-local"
                    placeholder={t('') ?? ''}
                    value={registration.local}
                    onChange={(e) => setRegistration({ ...registration, local: e.target.value })}
                    required
                    disabled={isRestricted}
                    label={t('Local')}
                    error={!!registrationError.local}
                    helperText={registrationError.local}
                    onClick={() => setRegistrationError({ ...registrationError, local: '' })}
                  />
                </S.InputWrapper>

                <S.InputWrapper>
                  <S.TextArea
                    id="input-descricao"
                    value={registration.descricao}
                    disabled={isRestricted}
                    onChange={(e) => setRegistration({ ...registration, descricao: e.target.value })}
                    label={t('Descrição')}
                    multiline
                    minRows={5}
                    maxRows={5}
                    error={!!registrationError.descricao}
                    helperText={registrationError.descricao}
                    onClick={() => setRegistrationError({ ...registrationError, descricao: '' })}
                  />
                </S.InputWrapper>
              </S.Flex>
            </S.Flex>
          </S.Container>
          <S.Container>
            <S.Flex>
              <S.InputWrapper>
                <S.MaterialInput
                  size="small"
                  type="text"
                  className="textCenter"
                  placeholder={t('Altura do Painel') ?? ''}
                  id="input-panelHeight"
                  required
                  value={registration.PanelHeight as string}
                  onChange={handleChangePainel}
                  label={t('Altura do Painel')}
                  error={!!registrationError.PanelHeight}
                  helperText={registrationError.PanelHeight}
                  onClick={() => setRegistrationError({ ...registrationError, PanelHeight: '' })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <S.MaterialInput
                  type="date"
                  name="CommissioningDate"
                  value={
                    registration.CommissioningDate
                      ? new Date(registration.CommissioningDate).toISOString().substring(0, 10)
                      : ''
                  }
                  onChange={handleInputChange}
                  label={t('Data de Comissionamento')}
                  variant="outlined"
                  size="small"
                  id="input-date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </S.InputWrapper>
            </S.Flex>
            <S.Flex>
              <S.InputWrapper>
                <S.MaterialInput
                  size="small"
                  className="textCenter"
                  placeholder={t('') ?? ''}
                  id="input-powerOutput"
                  value={registration.powerOutput}
                  onChange={(e) => {
                    const isValid = validarNumeroRealPositivo(e.target.value)

                    if (isValid || e.target.value === '')
                      setRegistration({ ...registration, powerOutput: e.target.value })
                  }}
                  error={!!registrationError.powerOutput}
                  helperText={registrationError.powerOutput}
                  onClick={() => setRegistrationError({ ...registrationError, powerOutput: '' })}
                  disabled={isRestricted}
                  label="Total MWDC"
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <S.MaterialInput
                  size="small"
                  className="textCenter"
                  placeholder={t('') ?? ''}
                  id="input-inversores"
                  value={registration.inverters}
                  onChange={(e) => setRegistration({ ...registration, inverters: e.target.value })}
                  disabled={isRestricted}
                  label={t('Fabricante e Modelo Inversores')}
                  error={!!registrationError.inverters}
                  helperText={registrationError.inverters}
                  onClick={() => setRegistrationError({ ...registrationError, inverters: '' })}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <S.MaterialInput
                  size="small"
                  className="textCenter"
                  placeholder={t('') ?? ''}
                  id="input-mounting"
                  value={registration.mounting}
                  onChange={(e) => setRegistration({ ...registration, mounting: e.target.value })}
                  disabled={isRestricted}
                  label={t('Fabricante Tracker')}
                  error={!!registrationError.mounting}
                  helperText={registrationError.mounting}
                  onClick={() => setRegistrationError({ ...registrationError, mounting: '' })}
                />
              </S.InputWrapper>
            </S.Flex>
            <S.Flex>
              <S.InputWrapper>
                <S.MaterialInput
                  size="small"
                  className="textCenter"
                  placeholder={t('') ?? ''}
                  id="input-modulos"
                  value={registration.moduleType}
                  onChange={(e) => setRegistration({ ...registration, moduleType: e.target.value })}
                  disabled={isRestricted}
                  label={t('Fabricante e Modelo do Módulo')}
                  error={!!registrationError.moduleType}
                  helperText={registrationError.moduleType}
                  onClick={() => setRegistrationError({ ...registrationError, moduleType: '' })}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <S.MaterialInput
                  size="small"
                  className="textCenter"
                  placeholder={t('') ?? ''}
                  id="input-moduleLayout"
                  value={registration.moduleLayout}
                  onChange={(e) => setRegistration({ ...registration, moduleLayout: e.target.value })}
                  disabled={isRestricted}
                  label={t('Quantidade de Módulos por String')}
                  error={!!registrationError.moduleLayout}
                  helperText={registrationError.moduleLayout}
                  onClick={() => setRegistrationError({ ...registrationError, moduleLayout: '' })}
                />
              </S.InputWrapper>
            </S.Flex>
            {user?.accessLevel === 1 && (
              <S.Flex
                style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}
              >
                {' '}
                <S.InputWrapper style={{ flex: 9 }}>
                  <S.MaterialInput
                    size="small"
                    className="textCenter"
                    placeholder={t('') ?? ''}
                    id="input-ArcGisName"
                    value={registration.ArcGisName}
                    onChange={(e) => {
                      setRegistration({ ...registration, ArcGisName: e.target.value })
                    }}
                    disabled={isRestricted}
                    label={t('Nome do Parque ArcGis')}
                    error={!!registrationError.ArcGisName}
                    helperText={registrationError.ArcGisName}
                    FormHelperTextProps={{
                      style: { color: getHelperTextColor(verifyPark) },
                    }}
                    InputLabelProps={{
                      sx: {
                        '&.Mui-error': {
                          color: 'inherit',
                        },
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#c5c5c5',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'inherit',
                      },
                    }}
                    onClick={() => setRegistrationError({ ...registrationError, ArcGisName: '' })}
                  />
                </S.InputWrapper>
                <S.InputWrapper style={{ flex: 1 }}>
                  <Button
                    variant="outlined"
                    onClick={verifyNamePark}
                    disabled={!registration.ArcGisName}
                    sx={{ padding: '7.5px 13px' }}
                  >
                    {t('Verificar')}
                  </Button>
                </S.InputWrapper>
              </S.Flex>
            )}
            <S.Flex className="col full">
              <S.InputWrapper>
                <S.TextArea
                  className="textCenter"
                  placeholder={t('') ?? ''}
                  id="input-panelInfo"
                  value={registration.panelInfo}
                  onChange={(e) => setRegistration({ ...registration, panelInfo: e.target.value })}
                  disabled={isRestricted}
                  label={t('Informações Adicionais dos Painéis')}
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!registrationError.panelInfo}
                  helperText={registrationError.panelInfo}
                  onClick={() => setRegistrationError({ ...registrationError, panelInfo: '' })}
                />
              </S.InputWrapper>
            </S.Flex>
            <S.Flex style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                style={{ backgroundColor: '#4CBB27', color: 'white' }}
                onClick={() => {
                  setActivityModalOperationMode('add')
                  setOpenActivityModalOperation(true)
                }}
              >
                {t('Adicionar Atividade')}
              </Button>
            </S.Flex>
            <ActivityModalOperation
              open={openActivityModalOperation}
              mode={activityModalOperationMode}
              activitiesList={activitiesList}
              selectedActivity={selectedActivity}
              handleClose={() => setOpenActivityModalOperation(false)}
            />
            <S.Flex className="col full" style={{ maxHeight: '365px', overflowY: 'auto' }}>
              <List>
                {activitiesData.map((activity) => (
                  <ListItem
                    key={activity.id}
                    sx={{
                      border: '1px solid rgb(25, 118, 210, 0.5)',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      paddingLeft: '16px',
                    }}
                  >
                    <ListItemIcon>
                      <Typography variant="body1">
                        {moment(activity.date).format('DD/MM/YYYY')}
                      </Typography>
                    </ListItemIcon>
                    <ListItemText
                      style={{ marginLeft: '20px' }}
                      primary={
                        <Typography
                          variant="body1"
                          component="span"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          {activity.activityName}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="body2"
                          style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-line',
                            color: 'rgba(0, 0, 0, 0.6)',
                          }}
                        >
                          {activity.additionalInfo}
                        </Typography>
                      }
                    />
                    <Box>
                      <Tooltip title={t('Excluir')} arrow>
                        <IconButton>
                          <Delete
                            color="error"
                            onClick={() => {
                              setSelectedActivity(activity)
                              setActivityModalOperationMode('delete')
                              setOpenActivityModalOperation(true)
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </S.Flex>
            {(user?.accessLevel === 1 || user?.accessLevel === 2) && params.siteId && (
              <S.Flex
                className="col full"
                style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
              >
                <MyModal params={userId} />
              </S.Flex>
            )}
            {user?.accessLevel === 1 && (
              <S.Flex
                className="col full"
                style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}
              >
                <Button variant="outlined" onClick={handleOpen} endIcon={<InsertPhotoIcon />}>
                  {t('Imagem Referência de Identificação de Módulos')}
                </Button>
                <ImageUploadModal
                  open={open}
                  onClose={handleClose}
                  existingImage={imagesData?.moduleReferenceImage.fileUrl ?? IMAGE}
                  originalFileName={imagesData?.moduleReferenceImage.originalFileName ?? t('Sem dados.')}
                  uploadDate={imagesData?.moduleReferenceImage.uploadDate ?? t('Sem dados.')}
                  sitioId={params.siteId ?? ''}
                />
              </S.Flex>
            )}
            <div style={{ gap: '10px', textAlign: 'center' }}>
              <S.Flex>
                <S.Text
                  style={{
                    width: '30%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {t('Coordenadas da UFV')}:
                </S.Text>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Tooltip
                    title={
                      t(
                        'Estamos esperando LATITUDE e LONGITUDE em suas respectivas ordens, EX: -22.92998156187511, -47.10292671793479',
                      ) ??
                      'Estamos esperando LATITUDE e LONGITUDE em suas respectivas ordens, EX: -22.92998156187511, -47.10292671793479'
                    }
                    placement="top"
                  >
                    <MaterialInfo color="info" />
                  </Tooltip>
                </Box>

                <S.InputWrapper>
                  <S.MaterialInput
                    size="small"
                    className="textCenter"
                    placeholder={t('Ex: -22.92998156187511, -47.10292671793479') ?? ''}
                    id="input-moduleLayout"
                    value={stringCoordinates}
                    onChange={(e) => setStringCoordinates(e.target.value)}
                    disabled={isRestricted}
                    label={t('Latitude e Longitude')}
                  />
                </S.InputWrapper>
              </S.Flex>

              <S.FileAndButtonWrapper>
                <Box flexDirection={'column'} width={'100%'} sx={{ marginTop: 'auto' }}>
                  {linkToDownload.diagram && (
                    <S.ImageNameWrapper>
                      <S.ImageTitle>
                        {t('Arquivo salvo')}: {imagesData?.electricalDiagram.originalFileName}
                      </S.ImageTitle>
                      <Tooltip
                        title={
                          <S.ImageDate>
                            {t('Data do upload')}:{' '}
                            {moment(imagesData?.electricalDiagram.uploadDate).format('DD/MM/YYYY')}
                          </S.ImageDate>
                        }
                        placement="top"
                      >
                        <MaterialInfo color="warning" />
                      </Tooltip>
                    </S.ImageNameWrapper>
                  )}
                  <S.ImageSelectorWrapper>
                    {selectedDiagram?.name ? (
                      <Box>
                        <S.ImageTitle onClick={handleDiagramClick}>{selectedDiagram?.name}</S.ImageTitle>
                      </Box>
                    ) : (
                      <Tooltip title={t('Adicionar diagrama elétrico')}>
                        <Button variant="outlined" startIcon={<Add />} onClick={handleDiagramClick}>
                          {t('Diagrama Elétrico')}
                        </Button>
                      </Tooltip>
                    )}
                    <input
                      ref={diagramInputRef}
                      type="file"
                      accept="image/jpeg, image/png, image/tiff"
                      onChange={handleDiagramChange}
                      style={{ display: 'none' }}
                    />
                  </S.ImageSelectorWrapper>

                  <Button
                    variant="contained"
                    style={{
                      marginTop: '8px',
                    }}
                    onClick={() => handleDownload(linkToDownload.diagram, 'diagram')}
                    fullWidth
                    disabled={!linkToDownload.diagram}
                  >
                    {t(`Baixar/Visualizar Diagrama`)}
                  </Button>
                </Box>

                <Box flexDirection={'column'} width={'100%'} sx={{ marginTop: 'auto' }}>
                  {linkToDownload.orthomosaic && (
                    <S.ImageNameWrapper>
                      <S.ImageTitle>
                        {t('Arquivo salvo')}: {imagesData?.orthomosaic.originalFileName}
                      </S.ImageTitle>
                      <Tooltip
                        title={
                          <S.ImageDate>
                            {t('Data do upload')}:{' '}
                            {moment(imagesData?.orthomosaic.uploadDate).format('DD/MMM/YYYY')}
                          </S.ImageDate>
                        }
                        placement="top"
                      >
                        <MaterialInfo color="warning" />
                      </Tooltip>
                    </S.ImageNameWrapper>
                  )}
                  <S.ImageSelectorWrapper>
                    {selectedOrtomosaic?.name ? (
                      <S.ImageNameWrapper>
                        <S.ImageTitle onClick={handleOrtomosaicClick}>
                          {selectedOrtomosaic?.name}
                        </S.ImageTitle>
                      </S.ImageNameWrapper>
                    ) : (
                      <Tooltip title={t('Adicionar ortomosaico')}>
                        <Button variant="outlined" startIcon={<Add />} onClick={handleOrtomosaicClick}>
                          {t('Ortomosaico')}
                        </Button>
                      </Tooltip>
                    )}
                    <input
                      ref={ortomosaicInputRef}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={handleOrtomosaicChange}
                      style={{ display: 'none' }}
                    />
                  </S.ImageSelectorWrapper>

                  <Button
                    variant="contained"
                    style={{
                      marginTop: '8px',
                    }}
                    onClick={() => handleDownload(linkToDownload.orthomosaic, 'ortomosaico')}
                    fullWidth
                    disabled={!linkToDownload.orthomosaic}
                  >
                    {t(`Baixar/Visualizar Ortomosaico`)}
                  </Button>
                </Box>
              </S.FileAndButtonWrapper>
            </div>
          </S.Container>

          <S.Container>
            <S.ButtonsWrapper className="full">
              <S.Button className="full" warning type="button" onClick={() => navigate(-1)}>
                <span>{t('Voltar')}</span>
              </S.Button>
              {(user?.accessLevel === 1 || user?.accessLevel === 2) && (
                <S.Button className="full" sucess type="button" onClick={RegisterAndEditSite}>
                  <span>{t('Salvar')}</span>
                </S.Button>
              )}
            </S.ButtonsWrapper>
          </S.Container>
        </S.ContainerForm>
      )}
    </BaseLayout>
  )
}

export default SiteCreateAndUpdate

interface ActivityModalOperationProps {
  open: boolean
  mode: 'add' | 'delete'
  activitiesList: { id: number; name: string; description: string | null }[]
  selectedActivity?: {
    id: string
    activityId: number
    activityName: string
    additionalInfo: string | null
    date: string
  }
  handleClose: () => void
}
const ActivityModalOperation = ({
  open,
  mode,
  activitiesList,
  selectedActivity,
  handleClose,
}: ActivityModalOperationProps) => {
  const siteId = useParams().siteId
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      activityType: '',
      description: '',
      date: '',
    },
  })

  const onSubmitAdd = async (data: any) => {
    try {
      const response = await postSiteActivity({
        siteId: siteId || '',
        activityId: data.activityType,
        additionalInfo: data.description,
        dateTime: moment(data.date).format('YYYY-MM-DDTHH:mm:ss'),
      })
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Atividade adicionada com sucesso!'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível adicionar a atividade. Tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível adicionar a atividade. Tente novamente mais tarde.'),
      })
    }

    handleClose()
    reset()
  }

  const onSubmitConfirm = async () => {
    if (selectedActivity) {
      try {
        const response = await deleteSiteActivity({ id: selectedActivity.id })
        if (response.success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Atividade excluída com sucesso!'),
          })
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível excluir a atividade. Tente novamente mais tarde.'),
          })
        }
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível excluir a atividade. Tente novamente mais tarde.'),
        })
      }

      handleClose()
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {mode === 'add' ? t('Adicionar Atividade') : t('Confirmar Exclusão')}
        </Typography>

        {mode === 'add' ? (
          <>
            <Controller
              name="activityType"
              control={control}
              render={({ field }) => (
                <TextField select label={t('Tipo') + ' *'} {...field} fullWidth margin="normal">
                  {activitiesList?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t('Descrição')}
                  {...field}
                  multiline
                  rows={4}
                  fullWidth
                  margin="normal"
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              )}
            />
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  value={field.value ? moment(field.value) : null}
                  shouldDisableDate={(date) => date.isAfter(moment().endOf('day'))}
                  onChange={(date: moment.Moment | null) => field.onChange(date ? date.format() : '')}
                  label={t('Data') + ' *'}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </>
        ) : (
          <Typography>
            {t('Você tem certeza de que deseja excluir esta atividade?')}{' '}
            <strong>{selectedActivity?.activityName}</strong>?
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="contained"
            color={mode === 'add' ? 'primary' : 'error'}
            onClick={mode === 'add' ? handleSubmit(onSubmitAdd) : onSubmitConfirm}
          >
            {mode === 'add' ? t('Adicionar') : t('Confirmar')}
          </Button>
          <Button variant="outlined" color="primary" onClick={handleClose} sx={{ marginLeft: 1 }}>
            {t('Cancelar')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
